<template>
  <div class="project-view" v-if="statement">
    <h1 class="project-title">{{$t('STATEMENT.TITLE')}}</h1>
    <div class="content-grid">
      <div class="content">
        <p
          class="description"
          v-html="$prismic.dom.RichText.asText(statement.data.description)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Statement',
  computed: {
    ...mapGetters({
      statement: 'content/getStatement'
    })
  }
}
</script>

<style lang="scss">
@import './../scss/colors.scss';

h3 {
  font-weight: 600;
  font-size: 24px;
  padding: 48px 0px 48px 0px;
}
q {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  color: $color-grey;
  line-height: 14px;
  display: block;
  margin-bottom: 2em;
}
a.quote {
  text-decoration: none;
  color: $color-grey;
}
span.quote {
  display: block;
  font-weight: 300;
  color: $color-grey;
  font-size: 16px;
  margin-top: 2em;
}
cite {
  font-style: italic;
}
b, strong {
  font-weight: 600;
}

.description > p {
  margin-bottom: 2em;
}
.cv > .content > .description > p {
  line-height: 24px;
}
sup { 
  vertical-align: super;
  font-size: smaller;
}

em {
  font-style: italic;
}

.project-img {
  width: 100%;
  padding-top: 50px;
}
.project-hero > img {
  min-width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  object-position: center;
}
.project-hero {
  overflow: hidden;
  height: 75vh;
}
.content-grid{
  // padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  // grid-auto-rows: calc(100%);
  grid-gap: 100px 100px;
}
.metadata {
  grid-column: 1 / span 2;
  margin-left: -2px;
}
.content {
  grid-column: 3 / span 5;
}
.project-title {
  font-size: 36px;
  padding: 50px 0px 75px 0px;
  font-weight: 600;
  color: $color-black;
}
.metadata-text {
  font-size: .9rem;
  color: $color-grey;
  font-weight: 300;
  padding-bottom: 1rem;
  line-height: 1.1rem;
}
.description {
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  color: $color-black;
}
</style>
